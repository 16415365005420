import VerificationForm from 'components/Form/Verification'
import { Modal } from 'antd'
import { IEmailVerifyProps } from './types'

function EmailVerify({ emailVerify, onCancel, onEmailVerify }: IEmailVerifyProps): JSX.Element {
  return (
    <Modal
      title="Email Verification"
      visible={emailVerify.isShowDialog}
      onOk={() => null}
      onCancel={onCancel}
      footer={false}
    >
      <VerificationForm
        label="Email Verification"
        name="emailVerification"
        onFinish={(values) => onEmailVerify(values)}
        resendText="Resend verify email"
      />
    </Modal>
  )
}

export default EmailVerify
