import { useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import FullScreenLoading from 'components/FullScreenLoading'
import AlertActivationOrganize from 'components/AlertActivationOrganize'

import useQueryParams from 'hooks/useQueryParams'

import { appLocalOrgTokenRef } from 'utils/localService/app'
import { PermissionContext } from 'contexts/PermissionProvider/PermissionProvider'
import { GENERATE_ORG_TOKEN_REF, GET_ACCOUNT_DATA } from 'graphQL/schemas/organization'

import { useOrganizationContext } from 'contexts/OrganizationProvider'

import type { IGenerateOrgTokenRefAPIResponse } from 'layouts/InnerOrganizationLayout/types'

import type { OrganizationAPIResponse, OrganizationStatus } from 'graphQL/schemaType/organization'
import { AppStateType } from 'redux/application/types'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { replacePath } from 'utils/mapText'
import type { IFrameOrganizationParams, IPathValue } from './types'
import useServiceMenuQuery from 'hooks/useServiceMenuQuery'
import { sortMenu } from 'utils/sortMenu'
import { useTranslation } from 'react-i18next'

function IFrameOrganization(): JSX.Element {
  const serviceKey = useQueryParams('serviceKey')
  const service: AppStateType = useSelector((state: RootState) => state.appReducer)
  const organization = useOrganizationContext()
  const permissionContext = useContext(PermissionContext)
  const orgTokenRef = appLocalOrgTokenRef.get()
  const checkPermission = permissionContext.permissionKey !== 'none'
  const { loading } = useServiceMenuQuery()
  const { i18n } = useTranslation()
  const selectedLanguage = i18n.language
  let locale: string = 'en'
  if (selectedLanguage === 'thTH') {
    locale = 'th'
  }

  const history = useHistory()
  if ((!serviceKey || serviceKey === 'undefined') && service.serviceMenus.length > 0) {
    const newServiceKey = sortMenu(service?.serviceMenus)[0]?.service.serviceKey
    history.replace(`${window.location.pathname}?serviceKey=${newServiceKey}`)
  }

  const accountResp = useQuery<OrganizationAPIResponse>(GET_ACCOUNT_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        orgKey: organization.orgKey,
      },
    },
  })
  const generateTokenRefResp = useQuery<IGenerateOrgTokenRefAPIResponse>(GENERATE_ORG_TOKEN_REF, {
    variables: {
      orgKey: organization.orgKey,
    },
    onCompleted(resp) {
      appLocalOrgTokenRef.set(resp.generateOrgTokenRef.payload.ref)
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const params = useParams<IFrameOrganizationParams>()

  const pathValue: IPathValue = {
    endpoint: service?.serviceMenus.find((service) => service.service?.serviceKey === serviceKey)?.service
      ?.adminPanelEndpoint,
    authorization_code: orgTokenRef,
    path: params.slug,
    locale: locale,
  }
  // console.log(pathValue)

  useEffect(() => {
    const listenHistory = history.listen(() => {
      generateTokenRefResp.refetch()
    })

    return () => {
      listenHistory()
    }
  }, [history, generateTokenRefResp])
  // console.log(checkPermission)
  return (
    <InnerOrganizationLayout>
      {generateTokenRefResp.loading || accountResp.loading || loading ? (
        <FullScreenLoading />
      ) : (
        <div className="admin-content-container">
          {!accountResp.loading && checkPermission && (
            <AlertActivationOrganize
              status={accountResp.data?.getOrganization.payload[0]?.status as OrganizationStatus}
            />
          )}
          <iframe
            title="Configuration"
            src={replacePath(
              service?.serviceMenus.find((service) => service.service.serviceKey === serviceKey)?.pattern || '',
              pathValue
            )}
            style={{ width: '100%', height: 'calc(100vh - 64px)' }}
            frameBorder="0"
          />
        </div>
      )}
    </InnerOrganizationLayout>
  )
}

export default IFrameOrganization
