import { Form, Input, Select } from 'antd'

import type { IPhoneNumberFormProps } from './types'

const { Option } = Select

function PhoneNumberForm({ input, prefix }: IPhoneNumberFormProps): JSX.Element {
  const prefixSelector = (
    <Form.Item name={prefix.name} noStyle initialValue={prefix.initialValue}>
      <Select disabled={prefix.disabled} style={{ width: prefix.width || 70 }}>
        {prefix.options.map(({ label, value }, index) => (
          <Option key={index} value={value}>
            {label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
  return (
    <Form.Item
      label={input.label}
      help={input.help}
      name={input.name}
      rules={input.rules}
      initialValue={input.initialValue}
    >
      <Input
        disabled={input.disabled}
        placeholder={input.placeholder}
        addonBefore={prefixSelector}
        style={{ width: '100%' }}
      />
    </Form.Item>
  )
}

export default PhoneNumberForm
