import { gql } from '@apollo/client'

export const GET_MY_PROFILE = gql`
  query {
    getMyProfile {
      message
      payload {
        attribute
        email {
          value
          verifyStatus
        }
        setting
      }
    }
  }
`

export const GET_MY_PERMISSION = gql`
  query {
    getMyPermission {
      message
      payload {
        permissionKey
        code
        scopeList
      }
    }
  }
`

export const UPDATE_MY_PROFILE = gql`
  mutation updateMyProfile($input: INPUT_UPDATE_PROFILE!) {
    updateMyProfile(input: $input) {
      message
    }
  }
`
export const CHANGE_MY_PASSWORD = gql`
  mutation changePassword($password: String!, $newPassword: String!, $confirmPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword, confirmPassword: $confirmPassword) {
      message
    }
  }
`

export const GET_MASTERDATA = gql`
  query getMasterData($input: INPUT_FIND_DATA!) {
    getMasterData(input: $input) {
      payload {
        parent
        dataKey
        locale
        text
        attribute
      }
    }
  }
`
