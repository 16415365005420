import jwtDecode from 'jwt-decode'

import type { ITokenPayload, TokenParam } from './interface'

export function decodeToken<T>(accessToken: TokenParam): T {
  if (accessToken == null) {
    return {} as T
  }

  const decode = jwtDecode<T>(accessToken)

  return decode
}

export function checkTokenExpired(accessToken: TokenParam): boolean {
  let isExpired = true
  try {
    const accessTokenPayload = decodeToken<ITokenPayload>(accessToken)

    if (!accessTokenPayload.exp || Date.now() < accessTokenPayload.exp * 1000) isExpired = false

    return isExpired
  } catch {
    return isExpired
  }
}
