import { CheckOutlined } from '@ant-design/icons'
import { Input, Form, Button } from 'antd'

import type { IVerificationFormProps } from './types'

function VerificationForm({ onFinish, loading, label, name }: IVerificationFormProps): JSX.Element {
  return (
    <Form name="basic" onFinish={onFinish} labelAlign="left" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Form.Item
        label={label}
        name={name}
        rules={[
          {
            required: true,
            message: 'Please input your email!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      {/* <span className="resend-verification-text">{resendText}</span> */}
      <Form.Item wrapperCol={{ span: 16, offset: 8 }}>
        <Button type="primary" icon={<CheckOutlined />} block loading={loading} htmlType="submit">
          Verify Now
        </Button>
      </Form.Item>
    </Form>
  )
}

export default VerificationForm
