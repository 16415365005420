import React, { useState } from 'react'
import { Button, Popconfirm, Table } from 'antd'

import useGetInviteUserOrganization from 'graphQL/useGetInviteUserOrganization'
import useDeleteInvite from 'graphQL/useDeleteInvite'

import { appLocalOrgKey } from 'utils/localService'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import TotalDataSourceText from 'components/Table/TotalDataSourceText'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

import type { ColumnType } from 'antd/lib/table'
import type { InviteUserOrganizationAPIPayload } from 'graphQL/useGetInviteUserOrganization/interface'

const Invitation: React.FC = () => {
  const orgKey = appLocalOrgKey.get() || ''

  const [searchValue, setSearchValue] = useState('')

  const inviteUserOrganizationQuery = useGetInviteUserOrganization({
    variables: {
      orgKey,
      input: {
        search: {
          email: searchValue,
        },
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [deleteInvite, deleteInviteResp] = useDeleteInvite({
    onCompleted() {
      inviteUserOrganizationQuery.refetch()
    },
  })

  const invitationUserOrganizationData: InviteUserOrganizationAPIPayload[] =
    inviteUserOrganizationQuery.data?.getInviteUserOrganization.payload || []

  const columns: ColumnType<InviteUserOrganizationAPIPayload>[] = [
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Email',
    },
    {
      key: 'action',
      align: 'right',
      width: 200,
      render: (_text, { _id }) => {
        return (
          <Popconfirm
            title="คุณแน่ใจที่จะลบไหม?"
            onConfirm={() =>
              deleteInvite({
                variables: {
                  inviteId: _id,
                  orgKey,
                },
              })
            }
            okButtonProps={{ loading: deleteInviteResp.loading }}
          >
            <Button type="primary" danger>
              ลบการเชิญนี้
            </Button>
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title="Invitation" />
        <ContentCard
          title="Invitation Member"
          rightComponent={<SearchInput onSearch={(value) => setSearchValue(value)} />}
        >
          <Table
            rowKey="_id"
            dataSource={invitationUserOrganizationData}
            loading={inviteUserOrganizationQuery.loading}
            columns={columns}
            pagination={{
              pageSize: 5,
            }}
          />
          <TotalDataSourceText total={invitationUserOrganizationData.length} />
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )
}

export default Invitation
