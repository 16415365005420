import { RcFile } from 'antd/lib/upload'
import axios from 'axios'
import mime from 'mime-types'
import { IResponse } from '../../components/UploadAttachFile/types'
import { IUploadToS3 } from './types'

function getFileMimeType({ name, type }: RcFile) {
  return type || mime.lookup(name)
}

export const uploadToS3 = async ({ file, signResult, onProgress, onComplete, onError, acl }: IUploadToS3) => {
  const fileType = getFileMimeType(file)
  const headers = {
    'content-type': fileType,
  }
  const config = {
    headers: {
      ...headers,
      'x-amz-acl': acl,
    },
    onUploadProgress(progressEvent: any) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      onProgress?.(percentCompleted)
    },
  }
  let response: IResponse = {}

  await axios
    .put(signResult.signedUrl, file, config)
    .then((res) => {
      response = { response: res, file }
      onComplete?.(response)
    })
    .catch((err) => {
      onError?.(err)
    })

  return response
}
