import { sortMenu } from 'utils/sortMenu'
import { SetAppActionType, SET_SERVICE_MENU, SET_APP_MENU, AppStateType } from './types'

const initialStateGetTest: AppStateType = {
  appService: {},
  serviceMenus: [],
  appMenus: [],
}

const appReducer = (state = initialStateGetTest, action: SetAppActionType): AppStateType => {
  switch (action.type) {
    case SET_SERVICE_MENU:
      return {
        ...state,
        serviceMenus: sortMenu(action.payload.serviceMenus),
      }
    case SET_APP_MENU:
      return {
        ...state,
        appMenus: sortMenu(action.payload.appMenus),
      }
    default:
      return state
  }
}

export default appReducer
