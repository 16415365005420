import { Table } from 'antd'

import { OrganizationApprovalTableProps } from './types'

const OrganizationApprovalTable = <T extends object>({
  dataSource,
  columns,
  rowKey,
  pagination,
  onPaginationChange,
}: OrganizationApprovalTableProps<T>): JSX.Element => (
  <Table
    dataSource={dataSource}
    columns={columns}
    rowKey={rowKey}
    pagination={{
      current: pagination.page,
      pageSize: pagination.limit,
      total: pagination.totalItems,
      onChange: onPaginationChange,
    }}
  />
)

export default OrganizationApprovalTable
