import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, List, Row } from 'antd'
import styled from 'styled-components'
import type { NotificationListProps } from './interface'
import NotificationIcon from './NotificationIcon'
import FullWidthSpace from 'components/FullWidthSpace'
import { generatePath, useHistory } from 'react-router'
import { routePaths } from 'pages/routeConfig'
import dayjs from 'dayjs'
import { useReadNotificationMutation } from 'graphQL/useReadNotification'
import { MyNotificationAPIPayload } from 'graphQL/useGetMyNotification/interface'

const NotificationDropdownList: React.FC<NotificationListProps> = ({ data }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [readNotification] = useReadNotificationMutation({
    fetchPolicy: 'network-only',
    onCompleted(resp) {
      const data = resp.readNotification
      history.push(
        generatePath(routePaths.notificationDetail, {
          id: data.payload[0]._id,
        })
      )
    },
  })
  const handleReadNotificaiton = (notification: MyNotificationAPIPayload) => {
    if (notification.read === 'NO') {
      readNotification({
        variables: {
          notificationIdList: [notification._id],
        },
      })
    } else {
      history.push(
        generatePath(routePaths.notificationDetail, {
          id: notification._id,
        })
      )
    }
  }

  const readStatus = (notification: MyNotificationAPIPayload): string => {
    let textDate = dayjs(notification.updatedAt).fromNow()
    textDate = notification.read === 'YES' ? t('notification:read') + ' ' + textDate : textDate
    return textDate
  }

  return (
    <Row gutter={32}>
      <Col span={24}>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <ListItemStyle key={item._id} onClick={() => handleReadNotificaiton(item)}>
              <List.Item.Meta
                avatar={
                  <NotificationIcon
                    type={item.attribute?.icon?.type || 'info'}
                    iconName={item.attribute?.icon?.iconName || 'info-circle'}
                  />
                }
                title={item.title}
                description={
                  <FullWidthSpace direction="vertical">
                    <span>{item.content}</span>
                    <DateTimeTitle>{readStatus(item)}</DateTimeTitle>
                  </FullWidthSpace>
                }
              />
            </ListItemStyle>
          )}
        />
      </Col>
    </Row>
  )
}

export default NotificationDropdownList

const DateTimeTitle = styled.span`
  color: #2699fb;
`
const ListItemStyle = styled(List.Item)`
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  &:hover {
    box-shadow: 0 0 4px #c1b7b7;
  }
`
