import React from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/PageTitle'
import AuthenticatedLayout from 'layouts/AuthenticatedLayout'
import { NotificationList } from 'components/Notification'

const Notification: React.FC = () => {
  const { t } = useTranslation()

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title={t('notification:title')} />

        <NotificationList />
      </div>
    </AuthenticatedLayout>
  )
}

export default Notification
