import { SaveOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Select, Space } from 'antd'
import { useQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// import { TYPE, regExpressValidation } from 'utils/validateInput'

import { GET_MASTER_DATA } from 'graphQL/schemas/masterData'

import type { IOrganizationFormProps } from './types'
import { GET_ALL_ORGANIZATION } from 'graphQL/schemas/organization'
const { Option } = Select

function NewOrganizationForm({ onFinish, loading }: IOrganizationFormProps): JSX.Element {
  const { t, i18n } = useTranslation()
  const locale = i18n.language === 'enUS' ? 'en' : 'th'
  const [diatlngCodeList, setDiatlngCode] = useState([])
  const [organizationList, setOrganization] = useState([])
  const [searchOrganiztion, setSearchOrganiztion] = useState('')
  const [countryList, setCountryList] = useState([])
  const diatlngCodeQuery = useQuery(GET_MASTER_DATA, {
    variables: {
      filter: {
        locale,
        parentKey: 'DIALING_CODE',
      },
      sort: {
        text: 1,
      },
    },
  })
  useEffect(() => {
    if (diatlngCodeQuery?.data?.getMasterData?.payload) {
      const diatlngCodeSelector = diatlngCodeQuery?.data?.getMasterData?.payload.map((element: any) => ({
        value: element.attribute,
        text: element.text,
      }))
      setDiatlngCode(diatlngCodeSelector)
    }
  }, [diatlngCodeQuery?.data?.getMasterData?.payload])

  const countryQuery = useQuery(GET_MASTER_DATA, {
    variables: {
      filter: {
        locale,
        parentKey: 'LANGUAGE',
      },
      sort: {
        dataKey: 1,
      },
    },
  })
  useEffect(() => {
    if (countryQuery?.data?.getMasterData?.payload) {
      const countrySelector = countryQuery?.data?.getMasterData?.payload.map((element: any) => ({
        value: element.dataKey,
        text: element.text,
      }))
      setCountryList(countrySelector)
    }
  }, [countryQuery?.data?.getMasterData?.payload])
  const organizationQuery = useQuery(GET_ALL_ORGANIZATION, {
    variables: {
      search: {
        name: searchOrganiztion,
      },
      pagination: {
        limit: 10,
      },
    },
  })
  useEffect(() => {
    if (organizationQuery?.data?.getAllOrganization?.payload) {
      const organizationSelector = organizationQuery?.data?.getAllOrganization?.payload || []
      setOrganization(organizationSelector)
    }
  }, [organizationQuery?.data])
  return (
    <Form
      name="organization"
      onFinish={onFinish}
      labelAlign="left"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 12 }}
      className="new-organization-form"
      requiredMark={false}
    >
      <Space direction="vertical" size={16}>
        <Form.Item
          label={'Parent Organization'}
          style={{ overflow: 'auto' }}
          name="parentOrganization"
          help={'Parent organization'}
          rules={[{ required: false, message: t('organization:new.contactNameRules') }]}
        >
          <Select showSearch onSearch={(value) => setSearchOrganiztion(value)} filterOption={false} allowClear>
            {organizationList.map((organization: any) => (
              <Option key={`organization${organization.orgKey}`} value={organization.orgKey}>
                {organization.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('organization:new.contactName')}
          style={{ overflow: 'auto' }}
          name="contactName"
          help={t('organization:new.contactNameDetail')}
          rules={[{ required: true, message: t('organization:new.contactNameRules') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('organization:new.organizationName')}
          style={{ overflow: 'auto' }}
          name="organizationName"
          help={t('organization:new.organizationNameDetail')}
          rules={[{ required: true, message: t('organization:new.organizationNameRules') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('organization:new.email')}
          help={t('organization:new.emailDetail')}
          name="contactEmail"
          rules={[{ required: true, message: t('organization:new.organizationEmailRules') }]}
        >
          <Input type="email" />
        </Form.Item>
        {/* {verifyStatus.email.isVerified && ( */}
        <>
          <Form.Item
            name="phone"
            help={t('organization:new.PhoneNumberDetail')}
            label={t('organization:new.PhoneNumber')}
            required
          >
            <Row gutter={8}>
              <Col span={20}>
                <Form.Item
                  className="mb-0"
                  name="phoneNumber"
                  rules={[{ required: true, message: t('organization:new.PhoneNumberRules') }]}
                >
                  <Input
                    addonBefore={
                      diatlngCodeList && (
                        <Form.Item name="prefix" noStyle initialValue="66">
                          <Select style={{ width: 200 }}>
                            {diatlngCodeList.map((element: any, index: number) => (
                              <Option key={`prefix_${index}`} value={element.value}>
                                {element.text}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )
                    }
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label={t('organization:new.contactLanguage')}
            name="contactLanguage"
            rules={[{ required: true, message: t('organization:new.contactLanguageRules') }]}
            initialValue="LANGUAGE.TH"
          >
            {countryList && (
              <Select style={{ width: 200 }}>
                {countryList.map((element: any, index: number) => (
                  <Option key={`contactLanguage_${index}`} value={element.value}>
                    {element.text}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
            <Button type="primary" htmlType="submit" loading={loading} icon={<SaveOutlined />}>
              {t('organization:new.saveOrganization')}
            </Button>
          </Form.Item>
        </>
        {/* )} */}
      </Space>
    </Form>
  )
}

export default NewOrganizationForm
