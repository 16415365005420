import { gql } from '@apollo/client'

export const APP_RESPONSE = `
  name
  appKey
  attribute
  status
  contactEmailList
`

export const SERVICE_RESPONSE = `
  name
  serviceKey
  permissionList {
    name
    permissionKey
    userType
    defaultCode
    scopeList
  }
  adminPanelMetaDataUrl
  adminPanelEndpoint
  endpoint{
    gql{
      system
      resourceAdmin
      resourceOwner
    }
    rest{
      system
      resourceAdmin
      resourceOwner
    }
  }
`

export const GET_APP_SERVICES = gql`
  query {
    getAppService {
      message
      code
      payload {
        app {
          ${APP_RESPONSE} 
        }
        serviceList {
          ${SERVICE_RESPONSE}
        }
      }
    }
  }
`
