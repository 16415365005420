import { Helmet } from 'react-helmet'
import React, { useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { changeAntdTheme } from 'dynamic-antd-theme'

import FullScreenLoading from 'components/FullScreenLoading'

import { GET_THEME } from 'graphQL/schemas/theme'

import API from 'constants/api'

import type { IDefaultProviderProps } from '../types'
import type { IAdminTheme, IThemeAPIResponse, IThemeAPIPayload, IThemeContextData, IThemeColor } from './types'

const defaultThemeContextData: IThemeContextData = {
  admin: {
    name: null,
    color: null,
    text: {
      description: '',
      navTitle: '',
      webTitle: '',
    },
    image: null,
    custom: null,
  },
  collapsed: false,
  collapseHandler() {},
}

export const ThemeContext = React.createContext<IThemeContextData>(defaultThemeContextData)

function ThemeProvider({ children }: IDefaultProviderProps): JSX.Element {
  const [admin, setAdminTheme] = useState<IAdminTheme>(defaultThemeContextData.admin)
  const [collapsed, setCollapsed] = useState(defaultThemeContextData.collapsed)

  const themeResp = useQuery<IThemeAPIResponse>(GET_THEME, {
    variables: {
      host: window.location.host,
      find: {
        filter: {
          isDefault: 'YES',
        },
      },
    },
    context: {
      uri: API.CORE.SYSTEM_ADMIN,
    },
    onCompleted(resp) {
      onThemeHandler(resp.getTheme.payload[0])
    },
    onError() {
      setupThemeColor({} as IThemeColor)
    },
  })

  function onThemeHandler(theme: IThemeAPIPayload) {
    setupTheme(theme)
    setAdminTheme(theme)
  }

  function collapseHandler(isCollapsed: boolean) {
    setCollapsed(isCollapsed)
  }

  function setupTheme(theme: IThemeAPIPayload) {
    setupThemeColor(theme.color)

    if (theme.image) {
      const { favIcon } = theme.image
      const favEle = document.getElementById('favicon') as HTMLLinkElement
      if (favIcon && favEle) {
        favEle.href = favIcon
      }
    }
  }

  function setupThemeColor(color: IThemeColor) {
    const fallbackColor = {
      primary: '#233060',
      secondary: '#bfbfbf',
      text: '#000',
      navText: '#fff',
    }

    const primary = color.primary || fallbackColor.primary
    const secondary = color.secondary || fallbackColor.primary
    const text = color.titleText || fallbackColor.text

    changeAntdTheme(primary)

    document.documentElement.style.setProperty('--app-primaryColor', primary)
    document.documentElement.style.setProperty('--app-secondaryColor', secondary)
    document.documentElement.style.setProperty('--app-titleTextColor', text)
    document.documentElement.style.setProperty('--app-navBgColor', color.navBg || primary)
    document.documentElement.style.setProperty('--app-navTextColor', color.navText || fallbackColor.navText)
    document.documentElement.style.setProperty('--app-navAuthBgColor', color.navAuthBg || '#fff')
    document.documentElement.style.setProperty('--app-navAuthTextColor', color.navAuthText || primary)
  }

  return (
    <ThemeContext.Provider
      value={{
        admin,
        collapsed,
        collapseHandler,
      }}
    >
      <Helmet>
        <title>{admin.text?.webTitle}</title>
      </Helmet>
      {themeResp.loading ? <FullScreenLoading /> : children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
