import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from 'contexts/AuthProvider'

import useGenerateOAuthLink from 'hooks/useGenerateOAuthLink'

import FullScreenLoading from 'components/FullScreenLoading'

import { goToSignInPage } from 'config'

const SignOutPage: React.FC = () => {
  const { t } = useTranslation()
  const auth = useAuthContext()

  const oauth = useGenerateOAuthLink()

  useEffect(() => {
    if (oauth.url) {
      auth.signOut()

      goToSignInPage(oauth.url)
    }
  }, [auth, oauth.url])

  return <FullScreenLoading loadingText={t('auth:signingOut')} />
}

export default SignOutPage
