import { GET_MY_PERMISSION } from 'graphQL/schemas/profile'
import { appLocalPermissionKey } from 'utils/localService/app'
import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { IDefaultProviderProps } from '../types'
import { IPermissionContextData, IPermissionAPIResponse } from './types'

const defaultPermissionContext: IPermissionContextData = {
  permissionKey: '',
  setPermissionKey() {},
}

export const PermissionContext = React.createContext<IPermissionContextData>(defaultPermissionContext)

function PermissionProvider({ children }: IDefaultProviderProps): JSX.Element {
  const [permissionKey, setPermissionKey] = useState(appLocalPermissionKey.get() || '')

  const permissionResp = useQuery<IPermissionAPIResponse>(GET_MY_PERMISSION, {
    // skip: true,
    onCompleted(resp) {
      // console.log(resp)
      const key = resp.getMyPermission.payload.find(
        (ele: any) => ele.permissionKey === 'ALL_ORG_MANAGEMENT' && ele.code.includes('EDIT')
      )?.permissionKey
      // console.log(key)
      onPermissionKeyHandler(key || 'none')
    },
    onError(err) {
      console.log('Error', err)
    },
  })

  function onPermissionKeyHandler(PermissionKey: any) {
    setPermissionKey(PermissionKey)
    appLocalPermissionKey.set(PermissionKey)
  }

  return (
    <PermissionContext.Provider
      value={{
        permissionKey,
        setPermissionKey: onPermissionKeyHandler,
      }}
    >
      {!permissionResp.loading && children}
    </PermissionContext.Provider>
  )
}

export default PermissionProvider

export const usePermissionContext = () => useContext(PermissionContext)
