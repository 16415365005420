import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import UserMenu from '../UserMenu'

import type { IAuthenticatedHeaderMenuProps } from './types'

function AuthenticatedHeaderMenu({ collapsed, toggle, organizationName }: IAuthenticatedHeaderMenuProps): JSX.Element {
  return (
    <div className="authenticated-header-menu-container">
      <div className="left-component">
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: toggle,
        })}
        {organizationName && <strong className="organization-name">{organizationName}</strong>}
      </div>
      <div className="right-component">
        <UserMenu />
      </div>
    </div>
  )
}

export default AuthenticatedHeaderMenu
