import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useQueryString from 'hooks/useQueryString'

import FullScreenLoading from 'components/FullScreenLoading'

import { useAuthContext } from 'contexts/AuthProvider'

import useGetTokenAuthCode from 'graphQL/useGetTokenAuthCode'

import useGenerateOAuthLink from 'hooks/useGenerateOAuthLink'

import { authorizationKey, goToSignInPage } from 'config'
import { routePaths } from 'pages/routeConfig'

const OAuth: React.FC = () => {
  const { t } = useTranslation()

  const auth = useAuthContext()
  const query = useQueryString()
  const history = useHistory()

  const oauth = useGenerateOAuthLink()

  const code = query.get(authorizationKey.code)
  const credential = query.get(authorizationKey.credential)

  const [tokenAuthCode] = useGetTokenAuthCode({
    onCompleted(resp) {
      auth.signIn(resp.getTokenAuthCode.payload.token)

      setTimeout(() => {
        history.push(routePaths.organization)
      }, 1000)
    },
    onError() {
      history.replace(`${routePaths.oauth}?permission=no`)
    },
  })

  useEffect(() => {
    if (oauth.url) {
      if (code && credential) {
        tokenAuthCode({
          variables: {
            code,
          },
          context: {
            headers: {
              credentialKey: credential,
            },
          },
        })
      } else {
        goToSignInPage(oauth.url)
      }
    }
  }, [code, credential, oauth.url, tokenAuthCode])

  return <FullScreenLoading loadingText={t('auth:signingIn')} />
}

export default OAuth
