import apolloClient from 'constants/initializeApolloClient'

import { ApolloProvider } from '@apollo/client/react'

import type { IDefaultContainerProps } from '../types'

function ApolloContainer({ children }: IDefaultContainerProps): JSX.Element {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default ApolloContainer
