import { useHistory } from 'react-router'
import SystemAdminLayout from 'layouts/SystemAdminLayout'
import PageTitle from 'components/PageTitle'
import ContentCard from 'components/Card/Content'
import { NewServiceForm } from 'components/Form/Service'

function NewService(): JSX.Element {
  const history = useHistory()

  function onBack() {
    history.goBack()
  }

  function onFinish(values: any) {
    console.log(values)
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="New Service" onBack={onBack} />
          <ContentCard title="New Service">
            <NewServiceForm onFinish={onFinish} />
          </ContentCard>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default NewService
