import React from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'

import { appLocalLocale, localeCode } from 'utils/localService'

import FontawesomeIcon from 'components/FontawesomeIcon'

const languages = {
  [localeCode.thTH]: { label: 'ไทย', value: localeCode.thTH },
  [localeCode.enUS]: { label: 'English', value: localeCode.enUS },
}

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation()

  const selectedLanguage = i18n.language

  return (
    <Dropdown overlay={renderLanguages} trigger={['click']}>
      <Button icon={<FontawesomeIcon iconName="globe" style={{ marginRight: 4 }} />}>
        {languages[selectedLanguage].label}
      </Button>
    </Dropdown>
  )

  function renderLanguages() {
    return (
      <Menu selectedKeys={[selectedLanguage]}>
        {Object.values(languages).map((language) => (
          <Menu.Item
            key={language.value}
            onClick={() => {
              window.location.reload()

              appLocalLocale.set(language.value)
              i18n.changeLanguage(language.value)
            }}
          >
            {language.label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }
}

export default LanguageSelector
