import { useState } from 'react'
import { message, Upload, Progress } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import axios from 'axios'

import API from 'constants/api'

import { decodeToken } from 'utils/token'
import uploadToS3 from 'utils/signedUrl'

import { useAuthContext } from 'contexts/AuthProvider/AuthProvider'

import { S3_GET_OBJECT, S3_PUT_OBJECT } from 'graphQL/schemas/signedUrl'

import type { RcFile, UploadChangeParam } from 'antd/lib/upload'
import type { UploadFile } from 'antd/lib/upload/interface'
import type {
  IfileAndFileKeyProps,
  IS3GetObjectAPIResponse,
  IS3ObjectAPIPayload,
  IS3PutObjectAPIResponse,
  IUploadAttachFileProps,
} from './types'
import type { ITokenPayload } from 'utils/token/interface'

const { Dragger } = Upload

export const PRIVATE = 'PRIVATE'
export const PUBLIC = 'PUBLIC'

function UploadAttachFile({
  attachFiles,
  handleAttachFile,
  multiple = true,
  privacy = PRIVATE,
}: IUploadAttachFileProps): JSX.Element {
  const auth = useAuthContext()
  const userId = decodeToken<ITokenPayload>(auth.token?.accessToken)?.userId
  const [loading] = useState<any>()

  async function beforeUpload(_file: RcFile, fileList: RcFile[]) {
    try {
      const mappedFiles = fileList.map((file) => ({
        acl: privacy,
        path: 'thumturakit/',
        objectName: file.name,
        contentType: file.type,
      }))

      const { status, data } = await axios.post<IS3PutObjectAPIResponse>(
        API.CORE.ADMIN,
        {
          query: S3_PUT_OBJECT,
          variables: {
            userId,
            inputs: multiple ? mappedFiles : mappedFiles[0],
          },
        },
        {
          headers: {
            authorization: auth.token?.accessToken,
          },
        }
      )

      if (status === 200) {
        const tempFiles: IfileAndFileKeyProps[] = fileList.map((file: any) => {
          const signedObject: IS3ObjectAPIPayload | undefined = data?.data.getS3PutObjectSignedUrl?.payload.find(
            (signedResp) => signedResp.filename.indexOf(file.name) >= 0
          )
          if (!signedObject) {
            message.error('SignedUrl not found.')
            return { ...file, status: 'error' }
          }
          file.fileKey = signedObject.fileKey
          file.signedUrl = signedObject.signedUrl
          file.url = ''
          return file
        })
        if (multiple) handleAttachFile([...attachFiles, ...tempFiles])
        else handleAttachFile([...tempFiles])
        return true
      }
      return false
    } catch (error) {
      message.error('Error while upload file. Please try again.')
      return false
    }
  }

  function onChange(info: UploadChangeParam<UploadFile<any>>) {
    const foundIndex = attachFiles.findIndex((file) => file.uid === info.file.uid)
    // attachFiles[foundIndex] = info.file
    if (info.file.status !== 'removed') {
      const tempAttachFiles = attachFiles
      tempAttachFiles[foundIndex] = info.file
      handleAttachFile(tempAttachFiles)
    }
    if (info.file.status === 'error') {
      handleAttachFile(info.fileList)
      message.error('Upload Error')
    }
    if (info.file.status === 'done') {
      handleAttachFile(info.fileList.map((file) => ({ ...file, url: file.url || file.xhr })))
      message.success('Upload completed')
    }
  }

  function onRemove(selectedFile: UploadFile<any>) {
    const filteredFiles = attachFiles.filter((file) => selectedFile.uid !== file.uid)
    handleAttachFile(filteredFiles)
  }

  async function customRequest({ onSuccess, onProgress, onError, file }: any) {
    try {
      const foundFile: IfileAndFileKeyProps | undefined = attachFiles.find((attachFile) => attachFile.uid === file.uid)
      if (!foundFile) {
        onError('File Not Found.')
      } else {
        message.info('Uploading...')
        // Upload to S3
        const upload = await uploadToS3({
          file,
          signResult: foundFile,
          onProgress: (percent: number) => {
            onProgress({ percent }, file)
          },
          onError,
          acl: privacy === PUBLIC ? 'public-read' : 'private',
        })
        if (upload) {
          if (privacy === PRIVATE) {
            const { status, data } = await axios.post<IS3GetObjectAPIResponse>(
              API.CORE.ADMIN,
              {
                query: S3_GET_OBJECT,
                variables: {
                  fileKeys: foundFile.fileKey,
                },
              },
              {
                headers: {
                  authorization: auth.token?.accessToken,
                },
              }
            )
            // GetSignedObject
            if (status === 200) {
              const payload = data?.data?.getS3GetObjectSignedUrl?.payload[0]
              onSuccess(null, payload.signedUrl)
            }
          } else {
            const regex = /\?(.*)/i
            const url = upload?.response.config.url.replace(regex, '')
            onSuccess(null, url)
          }
        }
      }
    } catch (error) {
      message.error('Error while upload file. Please try again.')
    }
  }

  return (
    <Dragger
      name="file"
      multiple={multiple}
      listType="picture"
      fileList={attachFiles}
      beforeUpload={beforeUpload}
      onChange={onChange}
      customRequest={customRequest}
      onRemove={onRemove}
    >
      <p className="ant-upload-drag-icon">
        {loading ? <Progress type="circle" percent={loading} /> : <InboxOutlined />}
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
    </Dragger>
  )
}

export default UploadAttachFile
