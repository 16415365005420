import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './inviteUserOrganization'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { InviteUserOrganizationAPIPayload } from './interface'

interface InviteUserOrganizationData {
  inviteUserOrganization: APIPayloadResponse<InviteUserOrganizationAPIPayload>
}

interface InviteUserOrganizationVars {
  emailList: string[]
  orgKey: string
}

const useInviteUserOrganization: GraphQLServiceMutationHook<InviteUserOrganizationData, InviteUserOrganizationVars> = (
  options
) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useInviteUserOrganization
