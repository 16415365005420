import { PageHeader } from 'antd'
import { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import { useHistory, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

import type { IBreadrumb, IPageTitlteProps } from './types'

function PageTitle({ isRootPage, title, subtitle, onBack, extra }: IPageTitlteProps): JSX.Element {
  const { t } = useTranslation()

  const breadcrumbsPageTitle = 'breadcrumbs'

  const location = useLocation()
  const history = useHistory()

  let prevPath = ''

  const breadcrumbs: IBreadrumb = {
    home: t(`${breadcrumbsPageTitle}:home`),
    organization: t(`${breadcrumbsPageTitle}:organization`),
    'profile-setting': t(`${breadcrumbsPageTitle}:profileSetting`),
    dashboard: t(`${breadcrumbsPageTitle}:dashboard`),
    booking: t(`${breadcrumbsPageTitle}:booking`),
    'unit-lock': t(`${breadcrumbsPageTitle}:unitLock`),
    'unit-management': t(`${breadcrumbsPageTitle}:unitManagement`),
    setting: t(`${breadcrumbsPageTitle}:setting`),
    create: t(`${breadcrumbsPageTitle}:create`),
    administrator: t(`${breadcrumbsPageTitle}:administrator`),
    account: t(`${breadcrumbsPageTitle}:account`),
    service: t(`${breadcrumbsPageTitle}:service`),
    application: t(`${breadcrumbsPageTitle}:application`),
    configuration: t(`${breadcrumbsPageTitle}:configuration`),
    approval: t(`${breadcrumbsPageTitle}:approval`),
    user: t(`${breadcrumbsPageTitle}:user`),
    notification: t(`${breadcrumbsPageTitle}:notification`),
  }

  const homePath: Route = {
    breadcrumbName: breadcrumbs.home,
    path: '/',
  }

  const splitPathname: Route[] = !isRootPage
    ? location.pathname
        .split('/')
        .map((pathname) => {
          const routeBreadcrumb = {
            breadcrumbName: breadcrumbs[pathname],
            path: `${prevPath}/${pathname}`,
          }

          if (pathname) {
            prevPath = `${prevPath}/${pathname}`
          }
          return routeBreadcrumb
        })
        .slice(1)
    : []

  function onClickBreadcrumb(route: Route) {
    history.push(route.path)
  }

  function routeRender(route: Route) {
    // fix show emtry path when splitPathname
    if (route.breadcrumbName == null) {
      return null
    }
    return (
      <span className="breadcrumb-link" key={route.path} onClick={() => onClickBreadcrumb(route)}>
        {route.breadcrumbName}
      </span>
    )
  }

  return (
    <PageHeader
      className="page-header-container"
      onBack={onBack}
      title={title}
      breadcrumb={{ routes: [homePath, ...splitPathname], itemRender: routeRender }}
      subTitle={subtitle}
      extra={extra}
    />
  )
}

export default PageTitle
