export const GET_TEST = 'GET_TEST'

export interface Test {
  test: string
}

export interface GetTestStateType {
  tests: Test[]
}

export interface GetTestActionType {
  type: typeof GET_TEST
  payload: Test[]
}

export type TestActionTypes = GetTestActionType
