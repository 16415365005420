import { useState } from 'react'
import { useQuery } from '@apollo/client'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

import { GET_APP_SERVICES } from 'graphQL/schemas/app'

import { useOrganizationContext } from 'contexts/OrganizationProvider'

import type { IMenuListConfig } from 'components/Sidebar/types'
import { useDispatch } from 'react-redux'

import { setAppMenuAction, setServiceMenuAction } from '../../redux/application/appAction'

import type {
  IApplicationServiceAPIResponse,
  IService,
  IServiceMenu,
  IAppMenu,
  IServiceMenuListAPIPayload,
  IUseServiceMenuQueryProps,
  IMenuPayload,
} from './types'

const staticMenuCount = 5

function useServiceMenuQuery(): IUseServiceMenuQueryProps {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(true)
  const [serviceMenus, setServiceMenu] = useState<IServiceMenu[]>([])
  const [appMenus, setAppMenu] = useState<IAppMenu[]>([])
  const [length, setMenuLength] = useState(0)

  const { i18n } = useTranslation()
  const locale = i18n.language === 'enUS' ? 'en' : 'th'

  const organization = useOrganizationContext()

  const appServiceResp = useQuery<IApplicationServiceAPIResponse>(GET_APP_SERVICES, {
    fetchPolicy: 'network-only',
    onCompleted(resp) {
      onRequestServiceMetadata(resp.getAppService.payload.serviceList)
    },
  })

  function onRequestServiceMetadata(services: IService[]) {
    let serviceMemuMapped: IServiceMenu[] = []
    let appMemuMapped: IAppMenu[] = []
    Promise.all(
      services.map(async (service) => {
        const resp = await axios.get<IServiceMenuListAPIPayload>(service.adminPanelMetaDataUrl, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        })

        if (resp.status === 200) {
          const MenuDataMapped = onMappingMenuData(service, resp.data.app, resp.data.organization)
          serviceMemuMapped.push(MenuDataMapped.serviceMenus)
          appMemuMapped.push(MenuDataMapped.appMenus)
          setMenuLength(length + resp.data.organization.menuList.length)
        }
      })
    )
    dispatch(setServiceMenuAction(serviceMemuMapped))
    dispatch(setAppMenuAction(appMemuMapped))
  }

  function onMappingMenuData(service: IService, appMenu: IMenuPayload, serviceMenu: IMenuPayload) {
    const mappedServiceMenus: IMenuListConfig[] = serviceMenu.menuList.map((menu, index) => ({
      key: `${service.serviceKey + menu.path + serviceMenus.length + index}`,
      linkTo: `/organization/${organization.orgKey}/${menu.path}`,
      title: menu.name[locale],
      iconName: menu.icon,
    }))
    const mappedAppMenus: IMenuListConfig[] = appMenu.menuList.map((menu, index) => ({
      key: `${service.serviceKey + menu.path + appMenus.length + index + staticMenuCount}`,
      linkTo: `/app/${menu.path}`,
      title: menu.name[locale],
      iconName: menu.icon,
    }))
    const serviceMemuMapped = [
      ...serviceMenus,
      {
        service,
        menuOrder: serviceMenu.menuOrder,
        pattern: serviceMenu.pattern,
        menus: mappedServiceMenus,
      },
    ]
    const appMemuMapped = [
      ...appMenus,
      {
        service,
        menuOrder: appMenu.menuOrder,
        pattern: appMenu.pattern,
        menus: mappedAppMenus,
      },
    ]
    setServiceMenu(serviceMemuMapped)
    setAppMenu(appMemuMapped)
    setLoading(false)
    return {
      serviceMenus: {
        service,
        menuOrder: serviceMenu.menuOrder,
        pattern: serviceMenu.pattern,
        menus: mappedServiceMenus,
      },
      appMenus: {
        service,
        menuOrder: appMenu.menuOrder,
        pattern: appMenu.pattern,
        menus: mappedAppMenus,
      },
    }
  }

  return {
    loading: appServiceResp.loading || isLoading,
    serviceMenus,
    allMenuLength: length,
  }
}

export default useServiceMenuQuery
