import SystemAdminLayout from 'layouts/SystemAdminLayout'
import PageTitle from 'components/PageTitle'
import { Tabs, Row, Col, Card } from 'antd'
import { FormOutlined } from '@ant-design/icons'

import ContentCard from 'components/Card/Content/Content'
import SearchInput from 'components/Input/Search'
import { useState } from 'react'
import SystemAdministratorLog from 'components/Dashboard/SystemAdmin/SystemAdministratorLog'
import { AdminDetailsAction, AdminDetailsinfo } from 'components/Form/Administrator'

function AdministratorDetail(): JSX.Element {
  const { TabPane } = Tabs
  const [searchVal, setSearchVal] = useState('')

  function onServiceSearch(searchValue: string) {
    setSearchVal(searchValue)
    console.log(searchVal)
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">
        <>
          <PageTitle title="Administrator Details" />

          <Tabs defaultActiveKey="details" size="large" tabBarGutter={50}>
            <TabPane tab="DETAILS" key="details" style={{}}>
              <Row gutter={20}>
                <Col span={10}>
                  <Card title="Information" extra={<FormOutlined />}>
                    <AdminDetailsinfo />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Other actions">
                    <AdminDetailsAction />
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="LOGS" key="log">
              <ContentCard
                title="LOGS"
                rightComponent={<SearchInput onSearch={onServiceSearch} placeholder="Search" />}
              >
                <SystemAdministratorLog />
              </ContentCard>
            </TabPane>
          </Tabs>
        </>
      </div>
    </SystemAdminLayout>
  )
}

export default AdministratorDetail
