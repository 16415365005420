import { gql } from '@apollo/client'

const INVITE_USER_ORGANIZATION = gql`
  mutation inviteUserOrganization($emailList: [String!]!, $orgKey: String!) {
    inviteUserOrganization(emailList: $emailList, orgKey: $orgKey) {
      message
      code
      payload {
        _id
        userId
        status
        email
      }
    }
  }
`

export default INVITE_USER_ORGANIZATION
