import { Menu, Spin } from 'antd'
import { Link } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import { routePaths } from 'pages/routeConfig'

import useQueryParams from 'hooks/useQueryParams'

import { RootState } from 'redux/store'
import { AppStateType } from 'redux/application/types'

import type { IServiceMenu } from 'hooks/useServiceMenuQuery'
import type { IInnerOrganizationSidebar } from '../types'
import { ServiceOrganizationSidebar } from '.'

import System from './System'
import { sortMenu } from 'utils/sortMenu'

function InnerOrganization({ isCollapsed }: IInnerOrganizationSidebar): JSX.Element {
  const { t } = useTranslation()
  const history = useHistory()
  const application: AppStateType = useSelector((state: RootState) => state.appReducer)

  const serviceKey = useQueryParams('serviceKey')

  if ((!serviceKey || serviceKey === 'undefined') && application.serviceMenus.length > 0) {
    const newServiceKey = sortMenu(application?.serviceMenus)[0]?.service.serviceKey
    history.replace(`${window.location.pathname}?serviceKey=${newServiceKey}`)
  }

  const findServiceMenuByPathname = application?.serviceMenus
    .find((service) => service?.service?.serviceKey === serviceKey)
    ?.menus.find((menu) => window.location.pathname === menu.linkTo)

  const currentRoute = findServiceMenuByPathname?.key || ''

  const allMenuLength = application?.serviceMenus.reduce((acc: any, cur: IServiceMenu) => acc + cur.menus.length, 0) + 1

  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      mode="inline"
      defaultSelectedKeys={['0']}
      selectedKeys={[currentRoute]}
    >
      {!application ? (
        <Menu.Item icon={<Spin />} key="loading-organize-menu">
          <span className="primary-color font-medium">Loading menu...</span>
        </Menu.Item>
      ) : (
        <>
          {!isCollapsed && (
            <>
              <Menu.Item key="back-to-organize">
                <LeftOutlined className="primary-color" />
                <span className="primary-color font-medium">{t('button:BacktoMainPage')}</span>
                <Link to={routePaths.organization} />
              </Menu.Item>
            </>
          )}
          {sortMenu(application?.serviceMenus).map((service, index) => (
            <ServiceOrganizationSidebar
              key={service.service.serviceKey}
              menus={service.menus}
              service={service.service}
              serviceKey={service.service.serviceKey}
              currentRoute={currentRoute}
            />
          ))}

          <System allMenuLength={allMenuLength} />
        </>
      )}
    </Menu>
  )
}

export default InnerOrganization
