import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { appLocalAccessToken, appLocalRefreshToken, clearAppLocal } from 'utils/localService/app'

import { checkTokenExpired, decodeToken } from 'utils/token'

import { clearReduxAction } from 'redux/rootAction'

import type { IAuthContextData, IToken } from './types'
import type { IDefaultProviderProps } from '../types'
import type { ITokenPayload } from 'utils/token/interface'

const defaultAuthContextData: IAuthContextData = {
  email: '',
  token: null,
  isLoggedIn: false,
  signIn() {},
  signOut() {},
}

export const AuthContext = React.createContext<IAuthContextData>(defaultAuthContextData)

function AuthProvider({ children }: IDefaultProviderProps): JSX.Element {
  const dispatch = useDispatch()
  const [email, setEmail] = useState(defaultAuthContextData.email)
  const [token, setToken] = useState<IToken | null>(defaultAuthContextData.token)

  function resetAuth() {
    setEmail(defaultAuthContextData.email)
    setToken(defaultAuthContextData.token)
  }

  const signOut = () => {
    resetAuth()
    dispatch(clearReduxAction())
    clearAppLocal()
  }

  const signIn = (authToken: IToken) => {
    const tokenPayload = decodeToken<ITokenPayload>(authToken.accessToken)
    const isSystemAdminRole = tokenPayload.userType === 'SYSTEM_ADMIN'

    appLocalAccessToken.set(authToken.accessToken)
    appLocalRefreshToken.set(authToken.refreshToken)

    setEmail(isSystemAdminRole ? tokenPayload.email.value : tokenPayload.email[0].value)
    setToken(authToken)
  }

  return (
    <AuthContext.Provider
      value={{
        email,
        token,
        isLoggedIn: !checkTokenExpired(appLocalAccessToken.get()),
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuthContext = () => useContext(AuthContext)
