import { IAppMenu, IServiceMenu } from '../../hooks/useServiceMenuQuery/types'

export const SET_SERVICE_MENU = 'SET_SERVICE_MENU'
export const SET_APP_MENU = 'SET_APP_MENU'

export interface AppStateType {
  appService: any
  serviceMenus: IServiceMenu[]
  appMenus: IAppMenu[]
}

export interface SetAppActionType {
  type: typeof SET_SERVICE_MENU | typeof SET_APP_MENU
  payload: AppStateType
}
