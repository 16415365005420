import { gql } from '@apollo/client'

const ORGANIZATION = `
  name
  orgKey
  contactName
  attribute
  status
  contactEmailList
  createdAt
  updatedAt
`

const ORGANIZATION_TOKEN_REF = `
  ref
  organization {
    name
    orgKey
  }
`

export const GET_ORGANIZATION = gql`
  query getOrganization($sort: JSON, $query: JSON,$search: JSON, $Id:ID, $pagination: INPUT_PAGINATION){
    getOrganization(
        input:{
            sort: $sort
            query:$query
            search: $search
            findOneById: $Id
            pagination: $pagination
        }
    ) {
      pagination{
        limit
        page
        totalItems
        totalPages
      }
      message
      code
      payload {
        ${ORGANIZATION}
      }
    }
  }
`
export const GET_ORGANIZATION_NAME = gql`
  query getOrganizationByName($sort: JSON, $query: JSON,$search: JSON, $Id:ID, $pagination: INPUT_PAGINATION , $name: String){
    getOrganizationByName(
        input:{
            sort: $sort
            query:$query
            search: $search
            findOneById: $Id
            pagination: $pagination
        }
        name: $name
    ) {
      pagination{
        limit
        page
        totalItems
        totalPages
      }
      message
      code
      payload {
        ${ORGANIZATION}
      }
    }
  }
`

export const GET_ORGANIZATION_TOKEN_WITH_REF = gql`
  query getOrgAccessTokenWithRef($tokenRef: String!) {
    getOrgAccessTokenWithRef(tokenRef: $tokenRef) {
      message
      payload {
        token {
          orgAccessToken
          orgRefreshToken
        }
      }
    }
  }
`
export const GET_ORG_FIELD = gql`
  query getOrgField($orgKeyList: [String]) {
    getOrgField(orgKeyList: $orgKeyList) {
      message
      code
      payload {
        orgKey
        fieldList {
          fieldKey
          title
          description
          content
          showInList
          order
        }
      }
    }
  }
`
export const GET_ACCOUNT_DATA = gql`
  query getOrganization($filter: JSON) {
    getOrganization(input: { filter: $filter }) {
      payload {
        ${ORGANIZATION}
      }
    }
  }
`
export const GET_MASTERDATA = gql`
  query getMasterData($filter: JSON) {
    getMasterData(input: { filter: $filter }) {
      payload {
        dataKey
        text
        attribute
      }
    }
  }
`

export const GET_ORG_APPROVAL = gql`
  query getOrgApprovalAttribute($filter: JSON) {
    getOrgApprovalAttribute(input: { filter: $filter }) {
      message
      code
      payload {
        approvalAttribute
      }
    }
  }
`
export const GET_S3GETOBJECT = gql`
  query getS3GetObjectSignedUrl($fileKeys: [String]) {
    getS3GetObjectSignedUrl(fileKeys: $fileKeys) {
      payload {
        signedUrl
        fileKey
      }
    }
  }
`

// mutation
export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: INPUT_ORGANIZATION_FORM!) {
    createOrganization(input: $input) {
      message
      code
      payload {
        name
        orgKey
        attribute
        contactEmailList
      }
    }
  }
`

export const UPDATE_ORG_APPROVAL = gql`
  mutation updateOrgApprovalAttribute($input: INPUT_ORGANIZATION_APPROVAL_FORM!) {
    updateOrgApprovalAttribute(input: $input) {
      message
      code
      payload {
        organization {
          name
          contactName
          orgKey
          attribute
          contactEmailList
          status
        }
        approvalAttribute
        status
      }
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($orgKey: String!, $input: INPUT_ORGANIZATION_UPDATE!) {
    updateOrganization(orgKey: $orgKey, input: $input) {
      message
      code
      payload {
        contactName
        name
        orgKey
        attribute
      }
    }
  }
`

export const UPDATE_ORG_STATUS = gql`
  mutation updateOrgStatus($orgKey: String!, $status: ENUM_ORGANIZATION_STATUS!) {
    updateOrgStatus(orgKey: $orgKey, status: $status) {
      message
      code
    }
  }
`

export const SEND_TO_VERIFY_EMAIL = gql`
  mutation sendToVerifyEmailOrg($email: String!) {
    sendToVerifyEmailOrg(email: $email) {
      message
      code
    }
  }
`

export const VERIFY_EMAIL = gql`
  mutation verifyEmailOrganization($email: String!, $verifyToken: String) {
    verifyEmailOrganization(email: $email, verifyToken: $verifyToken) {
      message
      code
      payload {
        name
        orgKey
      }
    }
  }
`
export const GET_LOCALE_TEXT = gql`
  query getLocaleText($input: INPUT_FIND_DATA!) {
    getLocaleText(input: $input) {
      message
      code
      payload {
        orgKey
        dataKey
        locale
        text
        attribute
      }
    }
  }
`

export const CREATE_LOCALE_TEXT = gql`
  mutation createLocaleText($input: INPUT_LOCALE_TEXT_FORM) {
    createLocaleText(input: $input) {
      message
      code
      payload {
        orgKey
        dataKey
        locale
        text
        attribute
      }
    }
  }
`

export const CREATE_LOCALE_TEXT_LIST = gql`
  mutation createLocaleTextList($inputList: [INPUT_LOCALE_TEXT_FORM!]!) {
    createLocaleTextList(inputList: $inputList) {
      message
      code
      payload {
        orgKey
        dataKey
        locale
        text
        attribute
      }
    }
  }
`

export const GENERATE_ORG_TOKEN_REF = gql`
  query generateOrgTokenRef($orgKey: String!) {
    generateOrgTokenRef(orgKey: $orgKey) {
      message
      code
      payload {
        ${ORGANIZATION_TOKEN_REF}
      }
    }
  }
`

export const GET_ALL_ORGANIZATION = gql`
  query getAllOrganization($sort: JSON, $query: JSON,$search: JSON, $Id:ID, $pagination: INPUT_PAGINATION){
    getAllOrganization(
        input:{
            sort: $sort
            query:$query
            search: $search
            findOneById: $Id
            pagination: $pagination
        }
    ) {
      pagination{
        limit
        page
        totalItems
        totalPages
      }
      message
      code
      payload {
        ${ORGANIZATION}
      }
    }
  }
`
