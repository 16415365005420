import { gql } from '@apollo/client'

export const GET_MASTER_DATA = gql`
  query getMasterData($sort: JSON, $query: JSON, $filter: JSON, $search: JSON, $Id: ID, $pagination: INPUT_PAGINATION) {
    getMasterData(
      input: { sort: $sort, query: $query, search: $search, findOneById: $Id, pagination: $pagination, filter: $filter }
    ) {
      message
      code
      payload {
        dataKey
        text
        attribute
      }
    }
  }
`
