import apiPath from 'constants/api'
import { decodeToken } from './token'

import type { ITokenPayload, TokenParam, UserType } from './token/interface'

export const getUserTypeEndpoint = (accessToken: TokenParam): string => {
  if (accessToken == null) {
    return apiPath.CORE.CLIENT
  }

  const tokenPayload = decodeToken<ITokenPayload>(accessToken)

  switch (tokenPayload.userType) {
    case 'SYSTEM_ADMIN':
      return apiPath.CORE.SYSTEM_ADMIN
    case 'RESOURCE_ADMIN':
      return apiPath.CORE.ADMIN
    case 'RESOURCE_OWNER':
      return apiPath.CORE.CLIENT
  }
}

export const checkUserType = (accessToken: TokenParam, userType: UserType) => {
  if (accessToken == null) {
    return null
  }

  const tokenPayload = decodeToken<ITokenPayload>(accessToken)

  return tokenPayload.userType === userType
}

export const getUserId = (accessToken: TokenParam) => {
  if (accessToken == null) {
    return null
  }

  const tokenPayload = decodeToken<ITokenPayload>(accessToken)

  return tokenPayload.userId
}
