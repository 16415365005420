import React from 'react'

import OrganizationPage, {
  NewOrganizationPage,
  OrganizationAccountPage,
  OrganizationMemberPage,
  OrganizationIFramePage,
  OrganizationInvitationPage,
} from './Organization'
import { MemberDetailPage, MemberLogPage } from './Organization/Member'
import SystemAdminPage, {
  CoreServicePage,
  NewServicePage,
  NewSystemAdministratorPage,
  SystemAdministratorDetailPage,
} from './SystemAdmin'
import NotificationPage from './Notification'
import ApprovalPage from './Approval'
import { IFrameApplication } from './IFrameApplication'
import OAuthPage from './oauth'
import SignOutPage from './signOut'
import NotificationDetail from './Notification/NotificationDetail'

type RouteType = {
  path: string
  component: React.ComponentType
}

type PrivateRouteType = RouteType & {}

export const routePaths = {
  oauth: '/oauth',
  organization: '/organization',
  organizationCreate: '/organization/create',
  organizationMember: '/organization/:orgKey/permissions/member',
  organizationMemberDetail: '/organization/:orgKey/permissions/member/:id',
  organizationMemberLog: '/organization/:orgKey/permissions/member/:id/log',
  organizationInvitation: '/organization/:orgKey/permissions/invitation',
  organizationAccount: '/organization/:orgKey/account',
  organizationDetail: '/organization/:orgKey/:slug',
  applicationDetail: '/app/:slug',
  systemAdmin: '/system-admin/:page',
  systemAdminServiceCreate: '/system-admin/service/create',
  systemAdminServiceDetail: '/system-admin/service/:key/coreservice',
  systemAdminAdministratorCreate: '/system-admin/administrator/create',
  systemAdminAdministratorDetail: '/system-admin/administrator/detail',
  approvalDetail: '/approval/:page',
  signOut: '/sign-out',
  notification: '/notification',
  notificationDetail: '/notification/:id',
}

export const nonRequiredAuthenticatedRoutes: RouteType[] = [
  {
    path: routePaths.oauth,
    component: OAuthPage,
  },
  {
    path: routePaths.signOut,
    component: SignOutPage,
  },
]

export const privateRoutes: PrivateRouteType[] = [
  {
    path: routePaths.organization,
    component: OrganizationPage,
  },
  {
    path: routePaths.organizationCreate,
    component: NewOrganizationPage,
  },
  {
    path: routePaths.organizationMember,
    component: OrganizationMemberPage,
  },
  {
    path: routePaths.organizationMemberDetail,
    component: MemberDetailPage,
  },
  {
    path: routePaths.organizationMemberLog,
    component: MemberLogPage,
  },
  {
    path: routePaths.organizationInvitation,
    component: OrganizationInvitationPage,
  },
  {
    path: routePaths.organizationAccount,
    component: OrganizationAccountPage,
  },
  {
    path: routePaths.organizationDetail,
    component: OrganizationIFramePage,
  },
  {
    path: routePaths.applicationDetail,
    component: IFrameApplication,
  },
  {
    path: routePaths.approvalDetail,
    component: ApprovalPage,
  },
  {
    path: routePaths.notification,
    component: NotificationPage,
  },
  {
    path: routePaths.notificationDetail,
    component: NotificationDetail,
  },
]

export const systemAdminRoutePaths: PrivateRouteType[] = [
  {
    path: routePaths.systemAdmin,
    component: SystemAdminPage,
  },
  {
    path: routePaths.systemAdminServiceCreate,
    component: NewServicePage,
  },
  {
    path: routePaths.systemAdminServiceDetail,
    component: CoreServicePage,
  },
  {
    path: routePaths.systemAdminAdministratorCreate,
    component: NewSystemAdministratorPage,
  },
  {
    path: routePaths.systemAdminAdministratorDetail,
    component: SystemAdministratorDetailPage,
  },
]
