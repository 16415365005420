import VerificationForm from 'components/Form/Verification'
import Modal from 'antd/lib/modal/Modal'
import { IPhoneNumberVerifyProps } from './types'

function PhoneNumberVerify({ phoneVerify }: IPhoneNumberVerifyProps): JSX.Element {
  return (
    <Modal title="Phone Verification with OTP" visible={phoneVerify.isShowDialog} onOk={() => null} footer={false}>
      <VerificationForm
        label="Phone Verification"
        name="phoneVerification"
        onFinish={(value: any) => console.log(value)}
        resendText="Resend OTP"
      />
    </Modal>
  )
}

export default PhoneNumberVerify
