import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import REFRESH_ACCESS_TOKEN from 'graphQL/useRefreshAccessToken/refreshAccessToken'
import { appLocalAccessToken, appLocalLocale, appLocalRefreshToken, localeCode } from 'utils/localService/app'

import api from './api'

const endpointContext = (url: string | undefined) => {
  if (url) {
    return url
  }

  return api.CORE.ADMIN
}

const endpointWsContext = (url: string | undefined) => {
  if (url) {
    return url
  }

  return api.CORE.SUBSCRIPTION
}

const authLink = setContext(async (_, { headers }) => {
  const accessToken = appLocalAccessToken.get()
  const lang = appLocalLocale.get()

  return {
    headers: {
      authorization: accessToken && `Bearer ${accessToken}`,
      'content-language': lang,
      ...headers,
    },
  }
})

const wsLink = new WebSocketLink({
  uri: endpointWsContext(undefined),
  options: {
    connectionParams: {
      authorization: appLocalAccessToken.get() && `${appLocalAccessToken.get()}`,
      'content-language': appLocalLocale.get(),
    },
    reconnect: true,
  },
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(async (error) => {
      switch (error.extensions?.code) {
        case 'TOKEN_IS_EXPIRED':
          const client = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache({
              addTypename: false,
            }),
          })

          const refreshAccessTokenResp = await client.mutate({
            mutation: REFRESH_ACCESS_TOKEN,
            variables: {
              refreshToken: appLocalRefreshToken.get(),
            },
          })

          const { accessToken, refreshToken } = refreshAccessTokenResp.data.refreshAccessToken.payload

          appLocalAccessToken.set(accessToken)
          appLocalRefreshToken.set(refreshToken)

          const oldHeaders = operation.getContext().headers

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
              'content-language': appLocalLocale.get() === localeCode.enUS ? 'en' : 'th',
            },
          })

          console.log('Forwarding the query', operation)

          return forward(operation).subscribe((observer) => {
            console.log(observer)
          })
        default:
          return
      }
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)

    if (typeof networkError === 'string' && /401/.test(networkError)) {
      console.log('Network error 401, do something')
    }
  }
})

const createLink = (http: any) => authLink.concat(errorLink.concat(http))

const httpLink = createLink(
  createHttpLink({
    uri: (option) => endpointContext(option.getContext().uri),
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

export function initializeApolloClient() {
  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })
}

const apolloClient = initializeApolloClient()

export default apolloClient
