import { gql } from '@apollo/client'

export const GET_APP_BY_EMAIL = gql`
  query getAppByEmail($email: String!) {
    getAppByEmail(email: $email) {
      message
      code
      payload {
        app {
          name
          appKey
          attribute
          status
        }
        credential {
          name
          type
          credentialKey
        }
      }
    }
  }
`

export const LOGIN_EMAIL = gql`
  mutation loginEmail($email: String!, $password: String!, $authType: ENUM_AUTH_TYPE!, $redirectUrl: String) {
    loginEmail(email: $email, password: $password, authType: $authType, redirectUrl: $redirectUrl) {
      message
      code
      payload {
        token {
          accessToken
          refreshToken
        }
        profile {
          email {
            value
            verifyStatus
          }
          attribute
        }
        code
        authType
        redirectUrl
        credentialKey
      }
    }
  }
`

export const GENERATE_AUTH_CODE = gql`
  mutation {
    generateAuthCode {
      payload {
        profile {
          _id
          email {
            value
          }
        }
        token {
          accessToken
        }
        code
        credentialKey
      }
    }
  }
`
