import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'

function UserApproval(): JSX.Element {
  return (
    <>
      <PageTitle title="User Approval" />
      <ContentCard title="User Approval">
        <h1>User Approval</h1>
      </ContentCard>
    </>
  )
}

export default UserApproval
