import { useHistory } from 'react-router'

import ContentCard from 'components/Card/Content'
import { AdministratorDetailForm } from 'components/Form/Administrator'
import PageTitle from 'components/PageTitle'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

function AdministratorDetail(): JSX.Element {
  const history = useHistory()

  function onBack() {
    history.goBack()
  }

  function onFinish() {}

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title="New Administrator" onBack={onBack} />
        <ContentCard title="Create new Administrator">
          <AdministratorDetailForm onFinish={onFinish} loading={false} />
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )
}

export default AdministratorDetail
