// import { gql } from '@apollo/client'

const SIGNED_URL_RESPONSE = `
  signedUrl
  publicUrl
  filename
  fileKey
`

export const S3_PUT_OBJECT = `
  query getS3PutObjectSignedUrl($userId:ID, $inputs:[INPUT_S3_PUT_OBJECT_SIGNED_URL]) {
    getS3PutObjectSignedUrl(
      userId: $userId
      inputs: $inputs
    ) {
      message
      code
      payload {
        ${SIGNED_URL_RESPONSE}
      }
    }
  }
`
export const S3_GET_OBJECT = `
  query getS3GetObjectSignedUrl($fileKeys: [String]) {
    getS3GetObjectSignedUrl(
      fileKeys: $fileKeys
      
    ) {
      message
      code
      payload {
        ${SIGNED_URL_RESPONSE}
      }
    }
  }
`
