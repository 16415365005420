import { IAppMenu, IServiceMenu } from '../../hooks/useServiceMenuQuery/types'

import { AppDispatch } from '../store'
import { SET_SERVICE_MENU, SET_APP_MENU } from './types'

export const setServiceMenuAction =
  (serviceMenu: IServiceMenu[]): any =>
  (dispatch: AppDispatch) =>
    dispatch({
      type: SET_SERVICE_MENU,
      payload: { serviceMenus: serviceMenu },
    })

export const setAppMenuAction =
  (appMenu: IAppMenu[]): any =>
  (dispatch: AppDispatch) =>
    dispatch({
      type: SET_APP_MENU,
      payload: { appMenus: appMenu },
    })
