import { Space, Table } from 'antd'
import { ArrowRightOutlined, FormOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { generatePath, useHistory } from 'react-router'

import { routePaths } from 'pages/routeConfig'

function AdministratorDashboard() {
  const history = useHistory()
  function goToAdministratorDetail() {
    history.push(generatePath(routePaths.systemAdminAdministratorDetail))
  }
  const columns = [
    {
      // eslint-disable-next-line no-undef
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_text: any, render: any) => (
        <span key="status-render" className="service-status">
          <Space size={5} style={{ color: 'green', fontSize: '15px' }}>
            <CheckCircleOutlined />
            {render.status}
          </Space>
        </span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <span key="manage" className="service-action-link">
          <Space size={16} style={{ fontSize: '15px' }}>
            <FormOutlined onClick={goToAdministratorDetail} />
            <ArrowRightOutlined onClick={goToAdministratorDetail} />
          </Space>
        </span>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      name: 'Khomphet Meesub',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
    {
      key: '2',
      name: 'Jim Green',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
    {
      key: '3',
      name: 'Joe Black',
      email: 'komphet.me@lattesoft.co.th',
      status: 'Active',
    },
  ]
  return <Table dataSource={data} columns={columns} />
}

export default AdministratorDashboard
