import { SaveOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Form, Input, Space, Button, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import UploadAttachFile from 'components/UploadAttachFile'

import AccountStatus from './AccountStatus'

import type { IOrganizationFormProps } from './types'

const { Option } = Select
const { Text } = Typography

function VerifyAccountForm({
  setAttachFiles,
  attachFiles,
  values,
  setValues,
  countryCodeData,
  setCountryCode,
  contact,
  setLanguage,
  cancelRequest,
}: IOrganizationFormProps): JSX.Element {
  const { t } = useTranslation()
  function handleAttachFiles(files: any[]) {
    setAttachFiles(files)
  }
  const onChangeLanguage = (value: any) => {
    setLanguage({ language: value })
  }
  const onChange = (event: any) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }
  const onChangeCode = (value: any) => {
    setCountryCode({ code: value })
  }
  const contactLang = (
    <Select style={{ width: 150 }} onChange={onChangeLanguage}>
      {contact.map((conlang: any) => (
        <Option key={conlang.dataKey} value={conlang.dataKey}>
          {conlang.text}
        </Option>
      ))}
    </Select>
  )
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 200 }} onChange={onChangeCode}>
        {countryCodeData.map((countryCode: any) => (
          <Option key={countryCode.dataKey} value={countryCode.dataKey}>
            {countryCode.text}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )

  return (
    <Space size={16} direction="vertical">
      <Form.Item
        label={t('organization:table.dashboard.account.title')}
        help={t('organization:table.dashboard.account.subtitle')}
        name="accountStatus"
      >
        <AccountStatus status={values.status} approver="Komphet Meesab" date={Date.now()} />
      </Form.Item>

      <Form.Item
        label={t('organization:table.dashboard.contact.title')}
        help={t('organization:table.dashboard.contact.subtitle')}
        name="contactName"
      >
        <Space size={12}>
          <Input disabled={values.status !== 'PREPARING'} value={values.contact} onChange={onChange} name="contact" />
        </Space>
      </Form.Item>

      <Form.Item
        label={t('organization:table.dashboard.name.subtitle')}
        name="organizationName"
        help={t('organization:table.dashboard.name.subtitle')}
      >
        <Space size={12}>
          <Input
            disabled={values.status !== 'PREPARING'}
            value={values.organization}
            onChange={onChange}
            name="organization"
          />
        </Space>
      </Form.Item>

      <Form.Item
        label={t('organization:table.dashboard.email.title')}
        help={t('organization:table.dashboard.email.subtitle')}
        name="email"
        initialValue="komphet.me@gmail.com"
      >
        <Space size={12}>
          <Input disabled value={values.email} />
        </Space>
      </Form.Item>

      {countryCodeData && countryCodeData.length > 0 && (
        <Form.Item
          name="phone"
          help={t('organization:table.dashboard.phone.subtitle')}
          label={t('organization:table.dashboard.phone.title')}
          required
        >
          <Form.Item className="mb-0" name="phone">
            <Input
              disabled={values.status !== 'PREPARING'}
              addonBefore={prefixSelector}
              style={{ width: '100%' }}
              placeholder="000000000"
              onChange={onChange}
              name="phone"
            />
          </Form.Item>
        </Form.Item>
      )}
      {(values.status === 'NEED_MORE_INFORMATIONHold' || values.status === 'PREPARINGHold') && (
        <>
          <Form.Item
            name="attachFile"
            help={t('organization:table.dashboard.file.subtitle')}
            label={t('organization:table.dashboard.file.title')}
          >
            <UploadAttachFile attachFiles={attachFiles} handleAttachFile={handleAttachFiles} />
          </Form.Item>
          <Form.Item label={t('organization:table.dashboard.language')} name="language">
            {contactLang}
          </Form.Item>
        </>
      )}
      {values.status === 'PREPARING' && (
        <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
          <Text type="warning">
            Please verify that the information is correct. After submitting an application You will not be able to
            change the information.
          </Text>
        </Form.Item>
      )}
      {(values.status === 'NEED_MORE_INFORMATION' ||
        values.status === 'PREPARING' ||
        values.status === 'REVIEWING') && (
        <>
          <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
            {values.status !== 'REVIEWING' && (
              <Button style={{ marginRight: 10 }} type="primary" htmlType="submit" icon={<SaveOutlined />}>
                {t('button:submmitRequest')}
              </Button>
            )}
            {(values.status === 'NEED_MORE_INFORMATION' || values.status === 'REVIEWING') && (
              <Button type="default" danger onClick={() => cancelRequest()} icon={<CloseCircleFilled />}>
                {t('button:cancelRequest')}
              </Button>
            )}
          </Form.Item>
        </>
      )}
    </Space>
  )
}

export default VerifyAccountForm
