import { appLocalOrgKey } from 'utils/localService/app'
import React, { useContext, useState } from 'react'
import { IDefaultProviderProps } from '../types'
import { IOrganizationContextData } from './types'

const defaultOrganizationContext: IOrganizationContextData = {
  orgKey: '',
  setOrgKey() {},
}

export const OrganizationContext = React.createContext<IOrganizationContextData>(defaultOrganizationContext)

function OrganizationProvider({ children }: IDefaultProviderProps): JSX.Element {
  const [orgKey, setOrgKey] = useState(appLocalOrgKey.get() || '')

  function onOrgKeyHandler(organizationKey: string) {
    setOrgKey(organizationKey)

    appLocalOrgKey.set(organizationKey)
  }

  return (
    <OrganizationContext.Provider
      value={{
        orgKey,
        setOrgKey: onOrgKeyHandler,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider

export const useOrganizationContext = () => useContext(OrganizationContext)
