import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import ContentCard from 'components/Card/Content'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'
import { OrganizationApprovalDashboard } from 'components/Dashboard/Approval'

import api, { defaultPagination } from 'constants/api'

import { GET_ORGANIZATION } from 'graphQL/schemas/organization'

import type { IPagination } from 'graphQL/types'
import type { OrganizationAPIResponse } from 'graphQL/schemaType/organization'

function OrganizationApproval(): JSX.Element {
  const [pagination, setPagination] = useState<IPagination>(defaultPagination)
  const [searchValue, setSearchValue] = useState('')

  const { t } = useTranslation()

  const organizationApproval = 'approval.organization'
  const organizationApprovalContentCard = `${organizationApproval}.contentCard`

  const organizationResp = useQuery<OrganizationAPIResponse>(GET_ORGANIZATION, {
    variables: {
      search: {
        name: searchValue,
      },
      pagination: {
        limit: pagination.limit,
        page: pagination.page,
      },
      sort: {
        createdAt: -1,
      },
      query: {
        status: { $ne: 'PREPARING' },
      },
    },
    context: {
      uri: api.CORE.ADMIN,
    },
    fetchPolicy: 'network-only',
  })

  function onOrganizationApprovalSearch(value: string) {
    setSearchValue(value)
  }

  function onPaginationChange(page: number, pageSize?: number | undefined) {
    setPagination({ page, limit: pageSize || pagination.limit })
  }

  return (
    <>
      <PageTitle title="Organization Approval" />
      <ContentCard
        loading={organizationResp.loading}
        title="Organization Approval"
        rightComponent={
          <SearchInput
            placeholder={t(`${organizationApprovalContentCard}:search:placeholder`)}
            onSearch={onOrganizationApprovalSearch}
          />
        }
      >
        {!organizationResp.loading && organizationResp.data && (
          <OrganizationApprovalDashboard
            dataSource={organizationResp.data?.getOrganization.payload}
            pagination={organizationResp.data.getOrganization.pagination}
            onPaginationChange={onPaginationChange}
          />
        )}
      </ContentCard>
    </>
  )
}

export default OrganizationApproval
