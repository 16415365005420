import { gql } from '@apollo/client'

export const GET_THEME = gql`
  query GetTheme($host: String!, $find: INPUT_FIND_DATA) {
    getTheme(host: $host, find: $find) {
      message
      code
      payload {
        appKey
        name
        themeKey
        themeType
        status
        isDefault
        color {
          primary
          secondary
          titleText
          navBg
          navText
          navAuthBg
          navAuthText
        }
        text {
          webTitle
          navTitle
          description
        }
        image {
          favIcon
          navLogo
          navAuthLogo
          navAuthCollapsedLogo
          webLogo
        }
        custom
      }
    }
  }
`
