export const oauthUrl = process.env.REACT_APP_OAUTH_URL
export const signIn = `${oauthUrl}/sign-in`
export const credentialApplicationSignIn = `${oauthUrl}/credential-application-sign-in`

export const authorizationKey = {
  clientID: 'client_id',
  credential: 'credential_key',
  authType: 'auth_type',
  redirectUrl: 'redirect_url',
  code: 'code',
  error: 'error',
}

export const goToSignInPage = (url: string | undefined, delay = 1000) => {
  setTimeout(() => {
    window.open(url, '_self')
  }, delay)
}
