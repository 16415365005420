import React from 'react'
import { Button, Form, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

interface InvitationMemberModalProps {
  visible: boolean
  loading?: boolean
  onCancel?: () => void
  onInviteMember?: (emailList: string[]) => void
}

interface InviteEmailForm {
  email: string
}

const InvitationMemberModal: React.FC<InvitationMemberModalProps> = ({
  visible,
  loading,
  onInviteMember,
  onCancel,
}) => {
  const { t } = useTranslation('invitationModal')

  const [form] = Form.useForm()

  return (
    <Modal title={t('title')} visible={visible} onCancel={onCancel} footer={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          form.resetFields()
          onInviteMember?.(values.emailList.map((email: InviteEmailForm) => email.email))
        }}
      >
        <Form.List name="emailList">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Form.Item
                  {...restField}
                  key={key}
                  name={[name, 'email']}
                  fieldKey={[fieldKey, 'email']}
                  rules={[{ required: true, message: t('global:requiredText') }]}
                >
                  <Input placeholder="อีเมล" suffix={<MinusCircleOutlined onClick={() => remove(name)} />} />
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  เพิ่มอีเมล
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('button')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default InvitationMemberModal
