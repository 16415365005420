import type { IColumnFormatProps } from './types'

function TableColumnFormat({ title }: IColumnFormatProps): JSX.Element {
  return (
    <div className="table-column-format-container">
      <p className="column-title">{title}</p>
    </div>
  )
}

export default TableColumnFormat
