import React from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from 'components/PageTitle'
import AuthenticatedLayout from 'layouts/AuthenticatedLayout'
import { Notification } from 'components/Notification'
import { useHistory } from 'react-router'
import { routePaths } from 'pages/routeConfig'

const NotificationDetail: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">
        <PageTitle title={t('notificationDetail:title')} onBack={() => history.push(routePaths.notification)} />

        <Notification />
      </div>
    </AuthenticatedLayout>
  )
}

export default NotificationDetail
