import { useHistory, useParams } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'

import FullScreenLoading from 'components/FullScreenLoading'

import useQueryParams from 'hooks/useQueryParams'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import { AppStateType } from 'redux/application/types'

import { RootState } from 'redux/store'

import { replacePath } from 'utils/mapText'

import type { IFrameApplicationParams, IGenerateAuthCodeAPIPayload, IGenerateAuthCodeAPIResponse } from './types'
import useServiceMenuQuery from 'hooks/useServiceMenuQuery'
import { useMutation } from '@apollo/client'
import { useOrganizationContext } from 'contexts/OrganizationProvider'
import { useEffect, useState } from 'react'
import { GENERATE_AUTH_CODE } from 'graphQL/schemas/auth'
import { message } from 'antd'
import { sortMenu } from 'utils/sortMenu'

function IFrameApplication(): JSX.Element {
  const serviceKey = useQueryParams('serviceKey')
  const params = useParams<IFrameApplicationParams>()
  const history = useHistory()
  const organization = useOrganizationContext()
  const application: AppStateType = useSelector((state: RootState) => state.appReducer, shallowEqual)
  const { loading } = useServiceMenuQuery()
  const [authCodeResp, setAuthCodeResp] = useState<IGenerateAuthCodeAPIPayload>()
  const [generateAuthCode, generateAuthCodeResp] = useMutation<IGenerateAuthCodeAPIResponse>(GENERATE_AUTH_CODE, {
    variables: {
      orgKey: organization.orgKey,
    },
    onCompleted(resp) {
      setAuthCodeResp(resp.generateAuthCode.payload)
    },
    onError(err) {
      message.error('Error while generate AuthCode. Please try again.')
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  if ((!serviceKey || serviceKey === 'undefined') && application.serviceMenus.length > 0) {
    const newServiceKey = sortMenu(application?.serviceMenus)[0]?.service.serviceKey
    history.replace(`${window.location.pathname}?serviceKey=${newServiceKey}`)
  }

  useEffect(() => {
    const listenHistory = history.listen(async () => {
      await generateAuthCode()
    })

    return () => {
      listenHistory()
    }
  }, [generateAuthCode, history])

  useEffect(() => {
    generateAuthCode()
  }, [generateAuthCode])

  return (
    <AuthenticatedLayout>
      {generateAuthCodeResp.loading || loading ? (
        <FullScreenLoading />
      ) : (
        <div className="admin-content-container">
          <iframe
            title="Configuration"
            src={replacePath(
              application?.appMenus.find((appMenu) => appMenu.service?.serviceKey === serviceKey)?.pattern || '',
              {
                endpoint: application?.appMenus.find((appMenu) => appMenu.service?.serviceKey === serviceKey)?.service
                  ?.adminPanelEndpoint,
                authorization_code: authCodeResp?.code,
                credentialKey: authCodeResp?.credentialKey,
                path: params.slug,
              }
            )}
            style={{ width: '100%', height: 'calc(100vh - 64px)' }}
            frameBorder="0"
          />
        </div>
      )}
    </AuthenticatedLayout>
  )
}

export default IFrameApplication
