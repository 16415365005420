import { GetTestStateType, GET_TEST, TestActionTypes } from './types'

const initialStateGetTest: GetTestStateType = {
  tests: [],
}

const testReducer = (state = initialStateGetTest, action: TestActionTypes): GetTestStateType => {
  switch (action.type) {
    case GET_TEST:
      return {
        ...state,
        tests: action.payload,
      }
    default:
      return state
  }
}

export default testReducer
