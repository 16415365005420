import { useState } from 'react'

import useGetCredentialByHost from 'graphQL/useGetCredentialByHost'

import useQueryString from './useQueryString'

import { authorizationKey, credentialApplicationSignIn, signIn } from 'config'

import { appLocalApp } from 'utils/localService'

import type { CredentialByHostAPIPayload } from 'graphQL/useGetCredentialByHost/interface'

const useGenerateOAuthLink = () => {
  const query = useQueryString()
  const credentialKey = query.get(authorizationKey.credential)

  const shouldSkip = credentialKey == null

  const [isPrepareApplicationInfo, setIsPrepareApplicationInfo] = useState(!shouldSkip)
  const [oauthLink, setOAuthLink] = useState<string | undefined>('')

  useGetCredentialByHost({
    variables: {
      host: window.location.host,
    },
    onCompleted(resp) {
      appLocalApp.set(JSON.stringify(resp.getCredentialByHost.payload))
      generateOAuthLink(resp.getCredentialByHost.payload)
    },
    onError() {
      generateOAuthLink()
    },
  })

  const generateOAuthLink = (applicationInfo?: CredentialByHostAPIPayload) => {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/oauth`

    if (applicationInfo) {
      setOAuthLink(
        `${credentialApplicationSignIn}?client_id=${applicationInfo.app.appKey}&credential_key=${applicationInfo.credential.credentialKey}&auth_type=AUTH_CODE&redirect_url=${redirectUrl}`
      )
    } else {
      setOAuthLink(`${signIn}?auth_type=AUTH_CODE&redirect_url=${redirectUrl}`)
    }

    setIsPrepareApplicationInfo(false)
  }

  return {
    loading: isPrepareApplicationInfo,
    url: oauthLink,
  }
}

export default useGenerateOAuthLink
