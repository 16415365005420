import { Menu } from 'antd'
import { Link, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FontawesomeIcon from 'components/FontawesomeIcon'

import { appLocalOrgKey } from 'utils/localService'

import type { ISystemOrganizationSidebarProps } from './types'
import type { IMenuListConfig, IRoute } from '../types'
import { routePaths } from 'pages/routeConfig'

function System({ allMenuLength }: ISystemOrganizationSidebarProps): JSX.Element {
  const route: IRoute = {
    permission: `${allMenuLength + 1}`,
    member: `${allMenuLength + 2}`,
    invitation: `${allMenuLength + 3}`,
    account: `${allMenuLength + 4}`,
  }

  const routeAsArray = window.location.pathname.split('/')
  const currentRoute = route[routeAsArray[routeAsArray.length - 1]]

  const orgKey = appLocalOrgKey.get() || ''

  const { t } = useTranslation('sidebar')

  const systemMenus: IMenuListConfig[] = [
    {
      key: route['permission'],
      linkTo: routePaths.organizationMember,
      title: 'permission',
      iconName: 'user-circle',
      subKey: 'permissionSubMenu',
      subMenus: [
        {
          key: route['member'],
          linkTo: generatePath(routePaths.organizationMember, {
            orgKey,
          }),
          title: 'member',
        },
        {
          key: route['invitation'],
          linkTo: generatePath(routePaths.organizationInvitation, {
            orgKey,
          }),
          title: 'invitation',
        },
      ],
    },
    {
      key: route['account'],
      linkTo: generatePath(routePaths.organizationAccount, {
        orgKey,
      }),
      title: 'organizationSetting',
      iconName: 'user-circle',
    },
  ]

  return (
    <Menu
      theme="light"
      className="organization-menu-container"
      defaultSelectedKeys={['0']}
      mode="inline"
      selectedKeys={[currentRoute]}
      defaultOpenKeys={['permissionSubMenu']}
    >
      <Menu.Item disabled key={'system'}>
        System
      </Menu.Item>
      {systemMenus.map((menu) => {
        if (menu.subMenus && menu.subMenus.length > 0) {
          return (
            <Menu.SubMenu key={menu.subKey} icon={<FontawesomeIcon iconName={menu.iconName} />} title={t(menu.title)}>
              {menu.subMenus.map((subMenu) => (
                <Menu.Item key={subMenu.key}>
                  <span>{t(subMenu.title)}</span>
                  <Link to={subMenu.linkTo} />
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        }

        return (
          <Menu.Item key={menu.key} icon={<FontawesomeIcon iconName={menu.iconName} />}>
            <span>{t(menu.title)}</span>
            <Link to={`${menu.linkTo}`} />
          </Menu.Item>
        )
      })}
    </Menu>
  )
}

export default System
