import { Menu } from 'antd'
import { Link } from 'react-router-dom'

import type { IServiceOrganizationSidebarProps } from './types'

function ServiceOrganization({
  menus,
  service,
  serviceKey,
  currentRoute,
}: IServiceOrganizationSidebarProps): JSX.Element {
  return menus.length !== 0 ? (
    <Menu theme="light" className="organization-menu-container" mode="inline" selectedKeys={[String(currentRoute)]}>
      <Menu.Item key={service.serviceKey + serviceKey} disabled>
        {service.name}
      </Menu.Item>
      <Menu.Divider />
      {menus.map((menu) => {
        const menuIconClass = `fas fa-inbox`
        return (
          <Menu.Item key={menu.key} icon={<i className={menuIconClass} />}>
            <span>{menu.title}</span>
            <Link to={`${menu.linkTo}?serviceKey=${service.serviceKey}`} />
          </Menu.Item>
        )
      })}
      <Menu.Divider />
    </Menu>
  ) : (
    <></>
  )
}

export default ServiceOrganization
