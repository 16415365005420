import { IPagination } from 'graphQL/types'

const API = {
  CORE: {
    HOST: process.env.REACT_APP_API_CORE_HOST || 'http://localhost:5000',
    HOST_WSS: process.env.REACT_APP_API_CORE_HOST_WSS || 'ws://localhost:5000',
    PATH: {
      SYSTEM_ADMIN: process.env.REACT_APP_API_CORE_PATH_SYSTEM_ADMIN || '/graphql/system-admin/v1.0',
      ADMIN: process.env.REACT_APP_API_CORE_PATH_ADMIN || '/graphql/admin/v1.0',
      CLIENT: process.env.REACT_APP_API_CORE_PATH_CLIENT || '/graphql/client/v1.0',
      SUBSCRIPTION: process.env.REACT_APP_API_CORE_PATH_SUBSCRIPTION || '/graphql/subscription',
    },
  },
  BOOKING: {
    HOST: process.env.REACT_APP_API_BOOKING_HOST || 'http://localhost:5000',
    PATH: {
      SYSTEM_ADMIN: process.env.REACT_APP_API_BOOKING_PATH_SYSTEM_ADMIN || '/graphql/system-admin/v1.0',
      ADMIN: process.env.REACT_APP_API_BOOKING_PATH_ADMIN || '/graphql/admin/v1.0',
      CLIENT: process.env.REACT_APP_API_BOOKING_PATH_CLIENT || '/graphql/client/v1.0',
      SUBSCRIPTION: process.env.REACT_APP_API_BOOKING_PATH_SUBSCRIPTION || '/graphql/subscription',
    },
  },
}

export const systemAdminAppKey = 'SYSTEM_ADMIN'

export const defaultPagination: IPagination = {
  limit: 10,
  page: 1,
}

const api = {
  CORE: {
    SYSTEM_ADMIN: API.CORE.HOST + API.CORE.PATH.SYSTEM_ADMIN,
    ADMIN: API.CORE.HOST + API.CORE.PATH.ADMIN,
    CLIENT: API.CORE.HOST + API.CORE.PATH.CLIENT,
    SUBSCRIPTION: API.CORE.HOST_WSS + API.CORE.PATH.SUBSCRIPTION,
  },
  BOOKING: {
    SYSTEM_ADMIN: API.BOOKING.HOST + API.BOOKING.PATH.SYSTEM_ADMIN,
    ADMIN: API.BOOKING.HOST + API.BOOKING.PATH.ADMIN,
    CLIENT: API.BOOKING.HOST + API.BOOKING.PATH.CLIENT,
  },
}

export default api
