import { useQuery } from '@apollo/client'
import { message } from 'antd'

import QUERY_DOCUMENT from './getInviteUserOrganization'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { InviteUserOrganizationAPIPayload } from './interface'

interface InviteUserOrganizationData {
  getInviteUserOrganization: APIPayloadResponse<InviteUserOrganizationAPIPayload[]>
}

interface InviteUserOrganizationVars {
  orgKey: string
  input?: FindDataInput
}

const useGetInviteUserOrganization: GraphQLServiceQueryHook<InviteUserOrganizationData, InviteUserOrganizationVars> = (
  options
) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useGetInviteUserOrganization
