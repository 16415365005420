import { useHistory } from 'react-router'

import ContentCard from 'components/Card/Content'
import { MemberLogDashboard } from 'components/Dashboard/Member'
import SearchInput from 'components/Input/Search'
import PageTitle from 'components/PageTitle'

import InnerOrganizationLayout from 'layouts/InnerOrganizationLayout'

function AdministratorLog(): JSX.Element {
  const history = useHistory()

  function onBack() {
    history.goBack()
  }

  function onSearch(key: string) {
    console.log(key)
  }

  return (
    <InnerOrganizationLayout>
      <div className="admin-content-container">
        <PageTitle title="Administrator Log" onBack={onBack} />
        <ContentCard title="Administrator Log Overview" rightComponent={<SearchInput onSearch={onSearch} />}>
          <MemberLogDashboard />
        </ContentCard>
      </div>
    </InnerOrganizationLayout>
  )
}

export default AdministratorLog
