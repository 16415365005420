import { generatePath, Redirect, useParams } from 'react-router'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import UserApproval from './UserApproval'
import OrganizationApproval from './OrganizationApproval'

import { routePaths } from '../routeConfig'

import type { ApprovalParamsPage, PageKey } from './types'

function Approval(): JSX.Element {
  const params = useParams<ApprovalParamsPage>()

  if (!params.page) {
    return (
      <Redirect
        to={generatePath(routePaths.approvalDetail, {
          page: 'organization',
        })}
      />
    )
  }

  function renderPanelByPageKey(pageKey: PageKey) {
    switch (pageKey) {
      case 'organization':
        return <OrganizationApproval />
      default:
      case 'user':
        return <UserApproval />
    }
  }

  return (
    <AuthenticatedLayout>
      <div className="admin-content-container">{renderPanelByPageKey(params.page)}</div>
    </AuthenticatedLayout>
  )
}

export default Approval
