import React from 'react'
import { Result } from 'antd'
import styled from 'styled-components'

import OAuth from 'components/OAuth'

import useQueryString from 'hooks/useQueryString'
import { useTranslation } from 'react-i18next'

const OAuthPage: React.FC = () => {
  const { t } = useTranslation('oauth')

  const query = useQueryString()
  const permission = query.get('permission')

  if (permission === 'no') {
    return (
      <PermissionDeniedWrapper>
        <Result status="error" title={t('permissionDenied.title')} subTitle={t('permissionDenied.description')} />
      </PermissionDeniedWrapper>
    )
  }

  return <OAuth />
}

export default OAuthPage

const PermissionDeniedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
