import { useMutation } from '@apollo/client'

import MUTATION_DOCUMENT from './deleteInvite'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import { message } from 'antd'

interface DeleteInviteData {
  deleteInvite: APIPayloadResponse<{ _id: string }>
}

interface DeleteInviteVars {
  inviteId: string
  orgKey: string
}

const useDeleteInvite: GraphQLServiceMutationHook<DeleteInviteData, DeleteInviteVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useDeleteInvite
