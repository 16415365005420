import { useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { Button, Space } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import ContentCard from 'components/Card/Content'
import PageTitle from 'components/PageTitle'
import SearchInput from 'components/Input/Search'
import AdministratorDashboard from 'components/Dashboard/SystemAdmin/SystemAdministrator'

import SystemAdminLayout from 'layouts/SystemAdminLayout'

import { SystemApplicationPage, SystemConfigurationPage, SystemDashboardPage, SystemServicePage } from '.'

import type { PageKey, SystemAdminParamsPage } from './types'
import { routePaths } from '../routeConfig'

function SystemAdmin(): JSX.Element {
  const params = useParams<SystemAdminParamsPage>()
  const history = useHistory()
  const [searchVal, setSearchVal] = useState('')

  function goToCreateNewService() {
    history.push(routePaths.systemAdminServiceCreate)
  }
  function goToNewAdministrator() {
    history.push(routePaths.systemAdminAdministratorCreate)
  }

  function onServiceSearch(searchValue: string) {
    setSearchVal(searchValue)
    console.log(searchVal)
  }

  function renderPanelByPageKey(pageKey: PageKey) {
    switch (pageKey) {
      case 'administrator':
        return (
          <>
            <PageTitle
              title="All Administrator"
              extra={[
                <Button type="primary" key="create" onClick={goToNewAdministrator}>
                  <Space size={12}>
                    <PlusCircleOutlined /> New Administrator
                  </Space>
                </Button>,
              ]}
            />
            <ContentCard title="All Administrator">
              <AdministratorDashboard />
            </ContentCard>
          </>
        )

      case 'application':
        return (
          <>
            <PageTitle title="Application" />
            <ContentCard title="Application">
              <SystemApplicationPage />
            </ContentCard>
          </>
        )
      case 'configuration':
        return (
          <>
            <PageTitle title="Configuration" />
            <ContentCard title="Configuration">
              <SystemConfigurationPage />
            </ContentCard>
          </>
        )
      case 'service':
        return (
          <>
            <PageTitle
              title="Service"
              extra={[
                <Button type="primary" key="create" onClick={goToCreateNewService}>
                  <Space size={12}>
                    <PlusCircleOutlined /> New Service
                  </Space>
                </Button>,
              ]}
            />
            <ContentCard
              title="Service"
              rightComponent={<SearchInput onSearch={onServiceSearch} placeholder="Search" />}
            >
              <SystemServicePage />
            </ContentCard>
          </>
        )
      default:
      case 'dashboard':
        return (
          <>
            <PageTitle title="Dashboard" />
            <ContentCard title="Dashboard">
              <SystemDashboardPage />
            </ContentCard>
          </>
        )
    }
  }

  return (
    <SystemAdminLayout>
      <div className="admin-content-container">{renderPanelByPageKey(params.page)}</div>
    </SystemAdminLayout>
  )
}

export default SystemAdmin
